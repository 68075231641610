<template>
  <UiContainer class="flex size-full flex-col items-center justify-center text-center">
    <template v-if="unavailableError">
      <AppErrorTemplate>
        <template #title> Что-то пошло не так </template>
        <template #description>
          Кажется что-то сломалось.<br />Проверьте соединение с интернетом<br />
          и повторите попыту.
        </template>
        <template #button>
          <UiButtonLink severity="primary" class="mt-2 w-full" @click="emit('reloadWidget')">
            Повторить попытку
          </UiButtonLink>
        </template>
      </AppErrorTemplate>
    </template>
    <template v-else-if="notFoundError">
      <AppErrorTemplate>
        <template #title> Не удалось найти сеанс </template>
        <template #description> Но у нас еще много всего интересного. </template>
        <template #button>
          <UiButton severity="primary" class="mt-2 w-full" @click="emit('deleteSeance')">
            Выбрать
          </UiButton>
        </template>
      </AppErrorTemplate>
    </template>
  </UiContainer>
</template>

<script lang="ts" setup>
import { AppErrorTemplate, UiButton, UiButtonLink, UiContainer } from '@/6_shared/ui'

type PropType = {
  notFoundError: boolean
  unavailableError: boolean
}

type EmitType = {
  (e: 'reloadWidget'): void
  (e: 'deleteSeance'): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()
</script>
